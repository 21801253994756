import React from 'react';
import { Image } from 'components';
import * as styles from './styles.module.scss';

const isDev = process.env.NODE_ENV === 'development';

const Section = (props) => {
  const { as, className, containerClassName, children, sliceName, noContainer, bgImage, spacing, parallax, id } = props;

  const HtmlElement = as || 'section';

  const spacingClassNames = {
    s: styles.smallSpacing,
    l: styles.largeSpacing,
  };

  return (
    <HtmlElement
      className={`${styles.section} ${spacingClassNames[spacing || '']} ${className || ''} ${
        parallax ? styles.parallax : ''
      }`}
      data-slice={isDev ? sliceName : null}
      id={id || ''}
    >
      {bgImage && (
        <div className={styles.imageContainer}>
          <Image image={bgImage} className={styles.sectionBg} />
        </div>
      )}
      {noContainer ? children : <div className={`${styles.container} ${containerClassName || ''}`}>{children}</div>}
    </HtmlElement>
  );
};

export default Section;
