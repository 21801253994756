export const styles = [
  {
    "featureType": "all",
    "stylers": [
        {
            "saturation": 0
        },
        {
            "hue": "#e7ecf0"
        }
    ]
  },
    {
        "featureType": "road",
        "stylers": [
            {
                "saturation": -80
            }
        ]
    },
    {
        "featureType": "transit",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "saturation": -15
            }
        ]
    }
];
