import React from 'react';
import { Section, RichText, Image } from 'components';
import * as styles from './styles.module.scss';

const TwoColsTextImage = (props) => {
  const { data } = props;
  const { primary, slice_type: sliceName } = data;
  const { content, image, subtitle, title } = primary;

  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const subtitleHtml = subtitle?.html;
  const hasImage = image?.url;

  return (
    <Section containerClassName={styles.container} sliceName={sliceName}>
      <div className={styles.textContainer}>
        {titleHtml && <RichText html={titleHtml} className={styles.title} />}
        {subtitleHtml && <RichText html={subtitleHtml} className={styles.subtitle} />}
        {contentHtml && <RichText html={contentHtml} className={styles.content} />}
      </div>
      {hasImage && <Image image={image} className={styles.image} />}
    </Section>
  );
};

export default TwoColsTextImage;
