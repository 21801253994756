import React from 'react';
import { Logo, Section } from 'components';
import * as styles from './styles.module.scss';

const Footer = () => (
  <Section as="footer" className={styles.footer} containerClassName={styles.container}>
    <p>Copyright Buck Wealth Management Limited {new Date().getFullYear()}</p>
    <p>
      Buck Wealth Management Limited is authorised and regulated by the Financial Conduct Authority. We are entered on
      the Financial Services register under number 797420.
    </p>
    <p>
      Registered In England & Wales No: 10786984. Registered Office: Northgate Business Centre, 38-40 Northgate, Newark,
      United Kingdom, NG24 1EZ
    </p>
    <p>
      The information contained within this website is subject to the UK regulatory regime and is therefore targeted at
      consumers in the UK only. Please note: The Financial Conduct Authority does not regulate Inheritance Tax Planning.
    </p>
  </Section>
);

export default Footer;
