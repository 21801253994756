import React from 'react';

const MapMarker = () => {
  return (
    <div className="mapmarker">
      <svg width="72px" height="84px" viewBox="0 0 72 84" fill="#000000">
        <path
          d="M75,10.5 C75,10.5 75,10.4 75,10.5 C61.2,-3.3 38.8,-3.3 25,10.5 C25,10.5 25,10.5 25,10.5 C11.2,24.3 11.2,46.8 25,60.6 L47.2,82.8 C48,83.6 49,84 50,84 C51,84 52,83.6 52.8,82.8 L75,60.5 C88.8,46.7 88.8,24.3 75,10.5 Z M50,46.5 C42,46.5 35.5,40 35.5,32 C35.5,24 42,17.5 50,17.5 C58,17.5 64.5,24 64.5,32 C64.5,40 58,46.5 50,46.5 Z"
          id="Shape"
        />
      </svg>
    </div>
  );
};
export default MapMarker;
