import React, { useState } from 'react';
import { Section, RichText, Form as FormComp } from 'components';
import * as styles from './styles.module.scss';

const Form = (props) => {
  const { data, anchor } = props;
  const { primary, slice_type: sliceName } = data;
  const { title } = primary;

  const [submissionSuccess, setSuccess] = useState(false);

  const thankYou = `<p>
      Thank you for contacting Buck Wealth Management, one of our Financial Planners will be in touch within 24 hours
    </p>`;

  const titleToUse = submissionSuccess ? thankYou : title?.html;

  return (
    <Section containerClassName={styles.container} className={styles.section} sliceName={sliceName} id={anchor || ''}>
      {title?.text && <RichText html={titleToUse} className={styles.title} h2 />}
      <FormComp className={styles.form} setSuccess={setSuccess} submissionSuccess={submissionSuccess} />
    </Section>
  );
};

export default Form;
