import React from 'react';
import { Section, RichText, Image, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const CenteredTextContent = (props) => {
  const { data } = props;
  const { primary, slice_type: sliceName } = data;
  const { content, icon, title } = primary;

  return (
    <Section containerClassName={styles.container} sliceName={sliceName}>
      <OnVisible className={styles.textContainer} visibleClassName={styles.visibleContainer}>
        {icon?.url && <Image image={{ url: icon?.url }} className={styles.icon} />}
        {title?.text && <RichText html={title.html} className={styles.title} />}
        {content?.text && <RichText html={content.html} className={styles.content} />}
      </OnVisible>
    </Section>
  );
};

export default CenteredTextContent;
