// extracted by mini-css-extract-plugin
export var activeLink = "styles-module--activeLink--+uNUu";
export var container = "styles-module--container--vzrwR";
export var header = "styles-module--header--a+NL6";
export var headerContainer = "styles-module--headerContainer--1pm5h";
export var headerLinks = "styles-module--headerLinks--VzwaU";
export var headerWithLinks = "styles-module--headerWithLinks--YOqT1";
export var link = "styles-module--link--PYJZu";
export var linkContainer = "styles-module--linkContainer--PPRCN";
export var logo = "styles-module--logo--Zb2XA";
export var scrolled = "styles-module--scrolled--gRxHI";
export var scrolledWithLinks = "styles-module--scrolledWithLinks--F73qR";