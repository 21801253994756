import React from 'react';
import GoogleMap from 'google-map-react';
import { Section, RichText, Image, OnVisible, MapMarker } from 'components';
import { styles as mapStyles } from '../../helpers/mapStyles';
import * as styles from './styles.module.scss';

const TwoColsText = (props) => {
  const { data, anchor } = props;
  const { primary, items, slice_type: sliceName } = data;
  const { grey_background: greyBg, title } = primary;

  const isMapVariant = !!items.find((item) => item?.longitude?.text && item?.latitude?.text);

  return (
    <Section
      containerClassName={styles.container}
      sliceName={sliceName}
      className={`${styles.section} ${greyBg ? styles.greyBg : ''}`}
      id={anchor || ''}
    >
      {title?.text && <RichText html={title?.html} className={styles.title} />}
      <div className={styles.items} style={{ gridTemplateColumns: `repeat(${items.length}, 1fr)` }}>
        {items.map((item) => {
          const { content, icon, title: itemTitle, longitude, latitude } = item;
          return (
            <OnVisible className={styles.item} visibleClassName={styles.visibleItem}>
              {icon?.url && !isMapVariant && <Image image={{ url: icon.url }} className={styles.icon} />}
              {isMapVariant && (
                <div className={styles.map}>
                  <GoogleMap
                    center={{ lat: parseFloat(latitude?.text), lng: parseFloat(longitude?.text) }}
                    defaultZoom={14}
                    options={{ mapStyles }}
                  >
                    <MapMarker lat={parseFloat(latitude?.text)} lng={parseFloat(longitude?.text)} />
                  </GoogleMap>
                </div>
              )}
              {itemTitle?.text && <RichText html={itemTitle?.html} className={styles.itemTitle} />}
              {content?.text && <RichText html={content?.html} className={styles.content} />}
            </OnVisible>
          );
        })}
      </div>
    </Section>
  );
};

export default TwoColsText;
