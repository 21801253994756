import React, { useState, useEffect } from 'react';
import { Section, RichText, OnVisible, Image } from 'components';
import * as styles from './styles.module.scss';

const TwoColsParallaxText = (props) => {
  const { data, anchor } = props;
  const { primary, slice_type: sliceName } = data;
  const { background_image: bgImage, align_right: alignRight, content, title } = primary;

  const [width, setWidth] = useState(null);
  const [breakPoint, setBreakPoint] = useState(null);

  // window listener
  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => {
      window.removeEventListener('resize', () => setWidth(window.innerWidth));
    };
  }, []);
  // set breakpoint state
  useEffect(() => {
    const widthRef = width || window.innerWidth;
    if (widthRef <= 900) {
      setBreakPoint(true);
    } else {
      setBreakPoint(false);
    }
  }, [width]);

  return (
    <Section
      containerClassName={styles.container}
      sliceName={sliceName}
      bgImage={bgImage}
      noContainer
      className={styles.section}
      parallax={!breakPoint}
      id={anchor || ''}
    >
      <div className={`${styles.itemContainer} ${alignRight ? styles.alignRightItem : ''}`} key={title.text}>
        {breakPoint && <Image image={bgImage} className={styles.mobileImage} />}
        <div className={styles.item}>
          <OnVisible className={styles.textContainer} visibleClassName={styles.visibleTextContainer}>
            {title?.text && <RichText html={title?.html} className={styles.title} />}
            {content?.text && <RichText html={content?.html} className={styles.content} />}
          </OnVisible>
        </div>
      </div>
    </Section>
  );
};

export default TwoColsParallaxText;
