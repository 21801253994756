import React from 'react';
import { Section, RichText, Mouse, OnVisible, Link } from 'components';
import * as styles from './styles.module.scss';

const FullHeightParallax = (props) => {
  const { data, anchor } = props;
  const { primary, slice_type: sliceName } = data;
  const { background_image: bgImage, title, cta_text: ctaText, cta_link: ctaLink } = primary;
  return (
    <Section
      containerClassName={styles.container}
      className={styles.section}
      sliceName={sliceName}
      bgImage={bgImage}
      parallax
      id={anchor || ''}
    >
      <OnVisible visibleClassName={styles.titleVisible} className={styles.titleContainer}>
        {title?.html && <RichText html={title.html} className={styles.title} />}
        {ctaText && (
          <Link to={ctaLink?.url} className={styles.link}>
            {ctaText}
          </Link>
        )}
      </OnVisible>
      <Mouse />
    </Section>
  );
};

export default FullHeightParallax;
