import React, { useState, useEffect } from 'react';
import { Logo, Section } from 'components';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import * as styles from './styles.module.scss';

const Header = () => {
  const anchors = [
    { text: 'Home', id: 'header' },
    { text: 'About', id: 'about' },
    { text: 'Financial Planning', id: 'planning' },
    { text: 'Contact', id: 'form' },
  ];

  const [hasScrolled, setHasScrolled] = useState(false); // set pixels to - from top when shownav = false
  const [active, setActive] = useState(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [linkElements, setLinkElements] = useState(anchors);

  // scroll logic
  const handleScroll = () => {
    const { scrollY, requestAnimationFrame } = window;
    requestAnimationFrame(() => {
      if (scrollY > 150 && !hasScrolled) setHasScrolled(true);
      if (scrollY === 0 && hasScrolled) setHasScrolled(false);
    });
  };
  // use effect to listen to scroll
  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [hasScrolled]);

  const classNames = `${styles.header} ${hasScrolled ? styles.scrolled : ''}`;

  const handleClick = (event, anchorId) => {
    event.preventDefault();
    const anchorElement = document.getElementById(`${anchorId}`);
    scrollIntoView(anchorElement, { behavior: 'smooth', block: 'start' });
  };

  const getLinkOffsets = () => {
    // Loop over sidebar items and get the offsetTop of the related divs
    anchors.forEach((anchorPoint, index) => {
      const { id } = anchorPoint;
      const anchorId = id;
      const linkElement = document.getElementById(anchorId);
      const offset = window.pageYOffset + linkElement.getBoundingClientRect().top;
      linkElements[index].offset = linkElement ? offset - headerHeight : 9999;
    });
    // Save sideLinks with offset keys to state
    setLinkElements(linkElements);
    setActive(active || linkElements[0]);
  };
  const scrollListener = () => {
    const scrollPosition = window.pageYOffset;
    const top = scrollPosition;
    const bottomOfPage = scrollPosition + window.innerHeight === document.documentElement.scrollHeight;
    const lastItem = linkElements[linkElements.length - 1];

    const getActive = (link, index, self) => {
      // First sidebar link selected when scroll is less than first item offset
      if (index === 0 && top < link.offset) return true;
      // Last sidebar link selected when check reaches end of array
      if (!self[index + 1]) return true;
      // Check whether top falls between active and next item's offset
      return top >= link.offset && top <= self[index + 1].offset;
    };

    const scrollActive = bottomOfPage ? lastItem : linkElements.find(getActive);

    if (scrollActive && scrollActive !== active) {
      setActive(scrollActive);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    // Set header height to state to offset against anchor point positioning
    const headerElement = document.querySelector('.header-nav');
    const additionalBuffer = 30;
    if (headerElement) setHeaderHeight(headerElement.offsetHeight + additionalBuffer);
    // Tidy up when unmounting component from DOM
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  useEffect(() => {
    // Fetch offsetTop values for elements matching sidebar ids
    getLinkOffsets();
  }, [headerHeight]);

  return (
    <>
      <Section as="header" className={classNames} containerClassName={styles.container}>
        <Logo className={styles.logo} />
      </Section>
      <Section
        as="header"
        className={`${styles.headerWithLinks} ${hasScrolled ? styles.scrolledWithLinks : ''}`}
        containerClassName={styles.headerContainer}
      >
        <Logo className={styles.logo} />
        <div className={styles.headerLinks}>
          {anchors.map((item) => {
            const { text, id } = item;
            const isActive = active?.id === id ? styles.activeLink : '';
            return (
              <div key={text} className={`${isActive} ${styles.linkContainer}`}>
                <a href={`#${id}`} onClick={(event) => handleClick(event, id)} className={styles.link}>
                  {text}
                </a>
              </div>
            );
          })}
          <div className={styles.linkContainer}>
            <a href="https://buckwealth.wrapadviser.co.uk/" className={styles.link} target="_blank" rel="noreferrer">
              Client Area
            </a>
          </div>
        </div>
      </Section>
    </>
  );
};

export default Header;
