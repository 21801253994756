import React from 'react';
import * as styles from './styles.module.scss';

const Mouse = () => (
  <div className={styles.scrollDown}>
    <div className={styles.mouse}>
      <div className={styles.scroller} />
    </div>
  </div>
);

export default Mouse;
