import React from 'react';
import { Footer, Header, SEO, NoJs, WmAscii } from 'components';
import '@fontsource/montserrat';
import '@fontsource/montserrat/200.css';
import '@fontsource/montserrat/600.css';
import '../../sass/global/styles.scss';
import './styles.scss';

const Layout = (props) => {
  const { children, seo, location } = props;
  return (
    <>
      <NoJs />
      <WmAscii />
      <Header />
      <main>
        <SEO {...seo} location={location} />
        {children}
      </main>
      <Footer />
    </>
  );
};

export default Layout;
